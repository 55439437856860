<template>
  <div class="font-inter antialiased text-gray-600">
    <div class="flex h-screen overflow-hidden">
      <MosaicUiCoreSidebar v-if="session">
        <WidgetCabinetHrSidebarMenu v-if="session.selectedProfile.type === 'business'" />
        <WidgetCabinetPsychologistSidebarMenu v-if="session.selectedProfile.type === 'psychologist'" />
        <WidgetCabinetCustomerSidebarMenu v-if="session.selectedProfile.type === 'customer'" />
        <WidgetCabinetEmployeeProfileSidebarMenu v-if="session.selectedProfile.type === 'employee'" />
      </MosaicUiCoreSidebar>
      <Teleport to="body">
        <MosaicUiCoreMobileMenu v-if="session" v-show="isSidebarOpened">
          <WidgetCabinetHrSidebarMenu v-if="session.selectedProfile.type === 'business'" />
          <WidgetCabinetPsychologistSidebarMenu v-if="session.selectedProfile.type === 'psychologist'" />
          <WidgetCabinetCustomerSidebarMenu v-if="session.selectedProfile.type === 'customer'" />
          <WidgetCabinetEmployeeProfileSidebarMenu v-if="session.selectedProfile.type === 'employee'" />
        </MosaicUiCoreMobileMenu>
      </Teleport>

      <div id="container"
           class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
           @click="onContainerClicked"
      >
        <MosaicUiCoreHeader>
          <template #left>
            <MosaicUiDropdownsLanguageSwitcher />
          </template>
          <template #center-end>
            <Clock class="px-1" />
          </template>
          <template #right>
            <WidgetAuthUserMenu />
          </template>
        </MosaicUiCoreHeader>

        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Clock from "@web/components/Clock.vue";

const { isSidebarOpened, toggleSidebar } = useCabinetPreferences();
const {session, fetchSession} = useAuthSession();

const {t} = useI18n();

function onContainerClicked() {
  if (isSidebarOpened.value) {
    toggleSidebar();
  }
}

onBeforeMount(async () => {
  await fetchSession();
});
</script>
