<template>
  <div ref="buttonElement" class="relative inline-flex">
    <button
        class="inline-flex justify-center items-center group"
        aria-haspopup="true"
    >
<!--      <img class="w-8 h-8 rounded-full" src="../images/user-avatar-32.png" width="32" height="32" alt="User" />-->
      <div class="flex items-center truncate">
        <Icon v-if="shownReverseButton"
              @click.prevent="onReverseClicked"
              class="hover:text-meclee-blue w-3 sm:w-auto"
              name="mage:reload-reverse"
        />
        <span @click.prevent="isDropdownOpened = !isDropdownOpened"
              class="flex flex-col sm:flex-row gap-1 items-center truncate ml-2 text-sm font-medium group-hover:text-gray-800"
        >
          <span>{{ session.selectedProfile.label }}</span>
          <span class="text-1.5xs sm:text-xs">({{ t(`auth.profiles.types.${session.selectedProfile.type}.short`) }})</span>
        </span>
        <svg @click.prevent="isDropdownOpened = !isDropdownOpened" class="w-3 h-3 shrink-0 ml-1 fill-current text-gray-400" viewBox="0 0 12 12">
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </div>
    </button>
    <div
        class="origin-top-right z-10 absolute top-full right-0 min-w-60 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        v-show="isDropdownOpened"
    >
      <div class="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
        <div class="font-medium text-gray-800 py-2">{{ session.selectedProfile.label }}</div>
<!--        <div class="text-xs text-gray-500 italic">HR</div>-->
      </div>
      <div class="pt-0.5 pb-2 px-0 mb-1 border-b border-gray-200" v-if="session.availableProfiles.length > 1">
        <p class="text-xs uppercase text-gray-400 font-semibold p-3">{{ t('auth.profiles.myProfiles') }}</p>
        <ul>
          <template v-for="profile in session.availableProfiles" :key="`profiles-${profile.id}`">
            <li class="flex flex-col py-1 px-3"
                v-if="profile.id !== session.selectedProfile.id"
            >
              <NuxtLink
                  @click="switchProfile(profile); isDropdownOpened = false;"
                  class="font-medium text-sm text-meclee-blue-800 hover:text-meclee-blue-400 cursor-pointer"
              >
                {{ t(`auth.profiles.switchTo`, {name: profile.label}) }} <span class="text-xs text-gray-400">({{ t(`auth.profiles.types.${profile.type}`) }})</span>
              </NuxtLink>
            </li>
          </template>
        </ul>
      </div>
      <ul>
        <li v-if="shownEmployeeProposal">
          <NuxtLink
            @click="onCreateCustomerProfile"
            class="font-medium text-sm text-meclee-blue-500 hover:text-meclee-black-600 flex items-center py-1 px-3 my-3"
          >
            {{ t(`auth.profiles.createCustomerProfile`) }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            :to="localePath(`/cabinet/settings`)"
            class="font-medium text-sm text-meclee-blue-700 hover:text-meclee-black-600 flex items-center py-1 px-3 my-3"
          >
            {{ t(`auth.settings.menu`) }}
          </NuxtLink>
        </li>

        <li>
          <NuxtLink @click="logout"
                    class="font-medium text-sm text-meclee-blue-500 hover:text-meclee-black-600 flex items-center py-1 px-3 cursor-pointer"
          >
            {{ t('auth.menu.logout') }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Ref} from "vue";
import { onClickOutside } from '@vueuse/core';
import {useAddProfile} from "#imports";

const buttonElement = ref();
onClickOutside(buttonElement, () => isDropdownOpened.value = false);

type MenuItem = {
  path: string;
  label: string;
  permission: any;
}

const { t } = useI18n();
const localePath = useLocalePath();
const {session} = useAuthSession();
const isDropdownOpened: Ref<boolean> = ref(false);
const { logout } = useLogin();
const { switchProfile } = useMultiProfile();

const {addCustomerProfile, profileResponse} = useAddProfile();
const { showLoader, hideLoader } = useLoader();

const shownEmployeeProposal = computed(() => {
  const hasCustomerProfile = session.value.availableProfiles.filter(profile => profile.type === 'customer').length > 0;
  return session.value.selectedProfile.type === 'employee' && !hasCustomerProfile;
});
const shownReverseButton = computed(() => {
  const hasCustomerProfile = session.value.availableProfiles.filter(profile => profile.type === 'customer').length > 0;
  const hasEmployeeProfile = session.value.availableProfiles.filter(profile => profile.type === 'employee').length > 0;
  return session.value.selectedProfile.type === 'employee' || (hasEmployeeProfile && hasCustomerProfile);
})

async function onCreateCustomerProfile() {
  showLoader();
  await addCustomerProfile();
  const flowId = profileResponse.value.autoLoginData.flowId;
  const token = profileResponse.value.autoLoginData.metadata.token;
  hideLoader();
  navigateTo(localePath(`/cabinet/auth/sign-in/${flowId}/${token}`))
}

async function onReverseClicked() {
  if (shownEmployeeProposal.value) {
    await onCreateCustomerProfile();
  } else {
    for (const profile of session.value.availableProfiles) {
      if (
          (session.value.selectedProfile.type === 'employee' && profile.type === 'customer') ||
          (session.value.selectedProfile.type === 'customer' && profile.type === 'employee')
      ) {
        try {
          showLoader();
          await switchProfile(profile);
        } finally {
          hideLoader();
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
